import React from 'react';
import styled from 'styled-components';
import { ITeamMember } from '../../content';
import { Column } from '../../layouts';

interface PhotoDivProps {
    photoUrl:string;
}

const Container = styled(Column)`
    max-width: 100%;
    padding: 16px 30px;
    background-color: ${props => props.theme.colors.bg};

    margin-bottom: 10px;
    box-sizing: border-box;
`;


const PhotoDiv = styled.div`
    display: block;
    height: 125px;
    width: 125px;
    margin: 20px auto;
    border-radius: 100%;
    border: 3px solid #fff;
    overflow: hidden;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${(props:PhotoDivProps) => `url(${props.photoUrl})`};

    @media only screen and (min-width: 768px) {
        height: 150px;
        width: 150px;
    }
`;

const NameDiv = styled.div`
    text-align: center;
    padding: 0.5em 0px;
    font-size: 24px;
    font-weight: bold; 
`;

const TitleDiv = styled.div`
    text-align: center;
    padding: 0.2em 0px;
    font-size: 16px;
    line-height: 1.2em;
`;

export interface AvatarProps {
    teamMember: ITeamMember;
    
}

const Avatar: React.SFC<AvatarProps> = (props) => {
    const photoUrl = `http://staffpicture.bcg.com/staffpicture/?mode=face&hrId=${props.teamMember.hrId}`;
    return (
        <Container xs='12' sm='6' md='3' {...props}> 
            <PhotoDiv photoUrl={photoUrl} />
            <NameDiv>{props.teamMember.name}</NameDiv>
            <TitleDiv>{props.teamMember.title}, {props.teamMember.location}</TitleDiv>
            <TitleDiv>{props.teamMember.department}</TitleDiv>
        </Container>
     );
}
 
export default Avatar;