import React from 'react';
import styled from 'styled-components';

function getWidthString(span:string|number|undefined) {
    if (!span) return;
    return `width: ${Number(span) / 12 * 100}%`;
}

interface ColProps {
    xs?: string | number;
    sm?: string | number;
    md?: string | number;
    lg?: string | number;
    lightTitle?: boolean;
    withCarousel?: boolean;
}

export const Row = styled.div`
    content: "";
    clear: both;
`;

export const Column = styled.div`
    ${(props:ColProps) => props.xs ? getWidthString(props.xs) : "width:100%"};

    @media only screen and (min-width: 768px) {
        ${(props:ColProps) => props.sm && getWidthString(props.sm)};
    }

    @media only screen and (min-width: 992px) {
        ${(props: ColProps) => props.md && getWidthString(props.md)};
    }
    
    @media only screen and (min-width: 1200px) {
        ${(props: ColProps) => props.lg && getWidthString(props.lg)};
    }
`;

export const Container = styled(Column)`
    padding-left: 30px;
    /* For centralize the children vertically */
    display: flex;
    flex-flow: column;
    
    background-color: ${props => props.theme.colors.bg};
    @media only screen and (min-width: 768px) {
        padding-left: 62px;
    }

    @media only screen and (min-width: 992px) {
        padding-left: 77px;
    }
    
    @media only screen and (min-width: 1200px) {
        padding-left: 92px;
    }
    min-height: ${(props:ColProps) => props.withCarousel ? `calc(100vh - 73px - 52px)` : `calc(100vh - 73px)`};
    box-sizing: border-box;
`;

// TODO: The height of menu should be read from loaded document, now it is hard-coded to 73px

// minusPadding is set up for child components without container padding settings (like iMac)
export const MinusPadding = styled.div`
    position: relative;
    margin-right: 30px;
    @media only screen and (min-width: 768px) {
        margin-right: 62px;
    }

    @media only screen and (min-width: 992px) {
        margin-right: 77px;
    }
    
    @media only screen and (min-width: 1200px) {
        margin-right: 92px;
    }
`;

const Header = styled(Column)`
    background-color: ${props => props.theme.colors.bg};
    color: ${props => props.lightTitle ? props.theme.colors.light : props.theme.colors.default};
    font-weight: 700;
    line-height: 1.1em;
    padding: 1.4em 0px;
`;

export const H1 = styled(Header)`
    font-size: 6em;
`;

export const H2 = styled(Header)`
    font-size: 2em;
`;