import React from 'react';
import styled from 'styled-components';
import { GroupEnum, ITeamMember } from '../../content';
import Avatar from './Avatar';

const GroupContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`; 

const GroupTitle = styled.div`
    border-top: 1px solid ${props => props.theme.colors.brand};
`;

const GroupTitleText = styled.p`
    display: inline-block;
    margin: 0px;
    padding: 10px 30px;
    font-size: 24px;
    color: ${props => props.theme.colors.light};
    background-color: ${props => props.theme.colors.brand};
`;

export interface TeamProps {
    team: Array<ITeamMember>;
}
 
const Team: React.SFC<TeamProps> = (props) => {
    const { team } = props;

    return ( 
        <div>
            {
                Object.keys(GroupEnum).map( group => 
                    <div key={`team-group-${group}`}>
                        <GroupTitle><GroupTitleText>{Object(GroupEnum)[group]}</GroupTitleText></GroupTitle>
                        <GroupContainer>
                            {team.filter(member => member.group === Object(GroupEnum)[group]).map(member => 
                                <Avatar 
                                    key={`team-member-${member.hrId}`}
                                    teamMember={member} />
                            )}
                        </GroupContainer>
                    </div>
                )
            }
        </div>
     );
}
 
export default Team;