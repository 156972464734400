import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const StyledButton = styled.button`
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    text-decoration-color: ${props => props.theme.colors.secondary};
    letter-spacing: 0.78px;
    height: 73px;
    padding-left: 30px;
    padding-right: 30px;

    &:hover {
        color: ${props => props.theme.colors.secondary};
    }

    &:focus {
        outline:0;
    }
`;

interface MenuButtonProps {
    name: string;
    active: boolean;
    to: string;
    onClick: (e:MouseEvent)=>void;
}
 
const MenuButton: React.SFC<MenuButtonProps> = (props) => {
    return (
        <Link to={props.to}>
            <StyledButton
                onClick={props.onClick}
                style={props.active ? { textDecorationLine: 'underline' } : {}}
            >
                {props.name}
            </StyledButton>
        </Link>
    );
}
 
export default MenuButton;