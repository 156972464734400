
const _ = require('lodash');
const imagesIndex = require('./image-index.json')

const siteImages = (site) => {
    if (imagesIndex[site]){
        return imagesIndex[site]
    }else{return {}}
}

const imagesMerged = _.merge({},siteImages('default'), siteImages(process.env.REACT_APP_SITE))
const images = _.mapValues(imagesMerged, (url => {return require('./' + url.split('content/').pop())}))
export default images