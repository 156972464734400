import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'

// TODO: need to add responsivity to the font size of title and padding in intros
const CellContainer = styled.div`
    @media only screen and (min-width: 768px) {
        flex: 0 1 50%;
    }

    @media only screen and (min-width: 992px) {
        flex: 0 1 25%;
    }
    
    @media only screen and (min-width: 1200px) {
        flex: 0 1 14.2%;
    }
    flex: 0 1 100%;
    min-width: 0;
`;

const ToolGrid = styled.div`
    height:100%;
    
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 2.5em auto 2.5em;
    grid-column-gap: 1em;
    grid-row-gap: 0.75em;
    grid-template-areas:
      "title title"
      "tool tool"
      "lefttext ${(props: { toolnumber: string | undefined }) => props.toolnumber ? 'righttext' : 'lefttext'}"
`;

const ToolTitle = styled.div`
    grid-area: title;
    height:2.5em;
    text-align: center;
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: bold;

    overflow:hidden;
    text-overflow:ellipsis;
`;


const Image = styled.img`
    grid-area:tool;
    pointer-events: ${(props:{disabled:boolean}) => props.disabled ? 'none' : 'default'};
    display: inline;
    margin: auto;
    object-fit: contain;
    max-height:100%;
    width:100%;
    opacity: ${(props: { disabled: boolean }) => props.disabled ? '0.5' : '1'};
    cursor: ${(props: { disabled: boolean }) => props.disabled ? 'default' : 'pointer'};
`;

const IntroLeft = styled.div`
    grid-area:lefttext;
    font-size: 0.8em;
    line-height: 1em;
    text-align: ${(props: { toolnumber: string | undefined }) => props.toolnumber ? 'right' : 'center'};
    height:2.2em;
    overflow:hidden;
    text-overflow:ellipsis;
`;

const IntroRight = styled.div`
    grid-area:righttext;
    text-align: left;
    font-size: 1.8em;
    /* font-weight: bold; */
    line-height: 0.75em;
    height:2.2em;
`;




export interface ToolCellProps {
    id: string;
    tool: any;
    images: any;
    handleClick: (tool:any) => void;
}
 
const ToolCell: React.SFC<ToolCellProps> = (props:any) => {
    const {tool, handleClick, images} = props;
    let toolNumber= undefined
    if(tool.introText.number){
        toolNumber = <IntroRight>{tool.introText.number}</IntroRight>
    }
    return ( 
        <ToolGrid toolnumber={tool.introText.number}>
            <ToolTitle>{tool.name}</ToolTitle>
            <Image src={
                //@ts-ignore
                images[tool.image]
                } 
                disabled={tool.url ? false : true}
                onClick={() => handleClick(tool)} />           
            <IntroLeft dangerouslySetInnerHTML={{ __html: tool.introText ? tool.introText.introHTML : ''}} toolnumber={tool.introText.number}></IntroLeft>
             {toolNumber}
        </ToolGrid> 
    );
}

const mapStateToProps= (state:any) => {
    return{
        images: state.images
    }
}
 
export default connect(mapStateToProps)(ToolCell);
