import React from 'react';
import styled from 'styled-components';



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';



const OverviewContainer = styled.div`

display: grid;

grid-template-rows: 5em auto;
grid-template-columns:auto, minmax(auto, 500px), auto;
grid-template-areas:
    "top top top"
    "left table right"  
`; 

const TableStyled =styled(Table)`
grid-area:table;
`;


const fileIndex = require('./../../content/file-index.json')

const getSiteOverviewData:any = () => {

    return Object.keys(fileIndex).map((folder:String)=> {
        if (folder === "default"){
            return { 
                eom: folder,
                mainUrl: {name:`autoanalytics.bcg.com/` , url:`https://autoanalytics.bcg.com/`},
                stageUrl: {name:`stage.autoanalytics.bcg.com/` , url:`https://stage.autoanalytics.bcg.com/`}
            }

        }else {
            return { 
                eom: folder,
                mainUrl: {name:`${folder}.autoanalytics.bcg.com/` , url:`https://${folder}.autoanalytics.bcg.com/`},
                stageUrl: {name:`stage.${folder}.autoanalytics.bcg.com/` , url:`https://stage.${folder}.autoanalytics.bcg.com/`}
            }
        }
    })
}
 
const SiteOverview: React.SFC = (props:any) => {
    const rows = getSiteOverviewData();

    const link = (name:string, url:string) => {
        return <a href={url}>{name}</a>
    }

    return ( 
        <OverviewContainer>
             <TableStyled  size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                <TableCell align="right">EOM</TableCell>
                <TableCell align="right">Main Site</TableCell>
                <TableCell align="right">Stage Site</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row:any) => (
                <TableRow key={row.eom}>
                    <TableCell component="th" scope="row">
                    {row.eom}
                    </TableCell>
                    <TableCell align="right">{link(row.mainUrl.name, row.mainUrl.url)}</TableCell>
                    <TableCell align="right">{link(row.stageUrl.name, row.stageUrl.url)}</TableCell>
                    
                </TableRow>
                ))}
          </TableBody>
        </TableStyled>
            
        </OverviewContainer>
     );
}
 
export default SiteOverview;