import React, { useState } from 'react'
import styled from 'styled-components'

import htmlFactory from './../../lib/htmlFactory/htmlFactory'

import { NONAME } from 'dns';

const DetailTitle = styled.div`
    border-top: 1px solid ${props => props.theme.colors.brand};
`;

const DetailTitleText = styled.p`
    display: inline-block;
    margin: 0px;
    margin-right: 2px;
    padding: 4px 30px;
    font-size: 20px;
    color: ${props => props.theme.colors.light};
    background-color: ${(props: { active:boolean, theme:any }) => props.active ? props.theme.colors.secondary : props.theme.colors.brand };
    pointer-events: ${(props:{ active:boolean }) => props.active ? 'none' : 'default'};
    cursor: ${(props: { active: boolean }) => props.active ? 'default' : 'pointer'};
`;

export interface DetailProps {
    tool: any;
}
 
const Detail: React.SFC<DetailProps> = (props:any) => {
    const [ activeInd, setActiveInd ] = useState<number>(0)
    const toolView:any =  Object.values(props.tool.details).map((detail:any, index:any) => ({
        title: <DetailTitleText key={`detail-title-text-${index}`} active={index === activeInd} onClick={() => setActiveInd(index)}>{detail.type}</DetailTitleText>,
        content: (<div key={`detail-title-content-${index}`} dangerouslySetInnerHTML={{ __html: htmlFactory(detail.innerHTML) }} />),
    }))
    if (toolView)
    return ( 
            <>
                <DetailTitle>
                    { toolView.map((el:any) => el.title) }
                </DetailTitle>
                { toolView[activeInd].content }
            </>
     );
     return <></>;
}
 
export default Detail;