const fs = require('fs');
const _ = require('lodash');
const dir = './src/content'

// This file create the file-index.json file at the startup of the container
// as webpack (use for react) cannot use the libary 'fs' this file is run at the start 
// before launching the react application
// the json file is later read in by react

function getFiles (dir, files_, re='.[0-9a-z]+$'){
    // read all the files in the given dir and push then to an array
    re = new RegExp(re);
    files_ = files_ || [];
    var files = fs.readdirSync(dir);
    for (var i in files){
        var name = dir + '/' + files[i];
        if (fs.statSync(name).isDirectory()){
            getFiles(name, files_, re);
        } else {
            if (re.test(name)){
                files_.push(name)
            }
        }
    }
    return files_;
}

function getAllFilesDir (dir, re){

    // read all folder in dir and call function getFiles on that dir
    var files = fs.readdirSync(dir);
    var filedir = {}
    for (var i in files){
        var name = dir + '/' + files[i];
        if (fs.statSync(name).isDirectory()){
            let newFileDir= {[files[i]]:getFiles(name,null, re)}
            filedir = { ...filedir, ...newFileDir }
        } else {
            console.log(`file: ${files[i]} in the dir : ${dir} will not be included`)              
        }
    }
    return filedir
}

let fileIdexJson = JSON.stringify(
    getAllFilesDir(dir +'/site-variations',".(json)$")
    )

let fileIdexImage = JSON.stringify(
    _.mapValues(
        getAllFilesDir(dir +'/site-variations','.(gif|jpg|jpeg|tiff|png|svg)$'),
        (urls) => {
            return urls.length === 0 ? [] : urls.reduce((obj, item) => {
                let imageName = item.split('/').pop().split('.')[0]
                return {...obj, [imageName] : item }
                }, {})
        }
    )
)

fs.writeFile((dir + "/file-index.json"), fileIdexJson, function(err) {
    if (err) {
        console.log(err);
    }
});

fs.writeFile((dir + "/image-index.json"), fileIdexImage, function(err) {
    if (err) {
        console.log(err);
    }
});
