
export const theme = {
    colors: {
        default: '#000',
        primary: '#333',
        secondary: '#32C77F',
        bg: '#FFF',
        light: '#FFF',
        dark: '#000',
        brand: '#187B57',
    }
};