import React from 'react';
import { connect } from 'react-redux'
import ArticleContainer from './container';
import Gif from './gif';

export interface ApproachProps {
    id:string;
    images:any;
    approachImage:any;
    title:any;
}
 
const Approach: React.SFC<ApproachProps> = (props:any) => {
    return ( 
        <ArticleContainer id={props.id} title={props.title.text}>
            <Gif src={props.images[props.approachImage]} />
        </ArticleContainer> 
     );
}


const mapStateToProps= (state:any) => {
    return{
        approachImage: state.variations.approach.image,
        title: state.variations.approach.title,
        images: state.images
    }
}
 
export default connect(mapStateToProps)(Approach);
