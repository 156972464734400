import React from 'react';
import styled from 'styled-components';
import { Container } from '../../layouts';

import { connect } from 'react-redux'

const BannerDiv = styled(Container)`
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    padding: 0 0 !important;
    height: 100vh;
    width: 100%;
    background-size: cover;
`;

const Title = styled(Container)`
    flex: 1 1 75%;
    position: relative;
    background-color: transparent;
    color: ${props => props.theme.colors.light};
    font-size: 6em;
    font-weight: 700;
    line-height: 1.1em;
    min-height: unset;
    margin-top: 80px;
`;

const Subtitle = styled(Container)`
    flex: 1 1 25%;
    position: relative;
    padding-top: 0.6em;
    padding-bottom: 1.5em;
    background-color: transparent;
    color: ${props => props.theme.colors.light};
    font-size: 2em;
    font-weight: 200;
    line-height: 1.1em;
    min-height: unset;
`;

const TitleSpan = styled.span`
    position: absolute;
    bottom: 0;
`;

export interface BannerProps {
    id:string;
    title:any;
    subtitle:any;
    imageName:string;
    images:any;
}

const Banner: React.SFC<BannerProps> = (props) => {
    return (  
        <BannerDiv {...props.id} style={{backgroundImage: `url(${props.images[props.imageName]})`}}>
            <Title xs='10'>
                <TitleSpan style={props.title.style} className="msg-wrapper">{props.title.text}</TitleSpan>
            </Title>
            <Subtitle style={props.subtitle.style} xs='10' className="msg-wrapper">
                {props.subtitle.text}
            </Subtitle>
            
        </BannerDiv>
    );
}


const mapStateToProps= (state:any) => {
    return{
        title: state.variations.banner.textheading.title,
        subtitle: state.variations.banner.textheading.subtitle,
        imageName: state.variations.banner.backgroundImage,
        images: state.images
    }
}
 
export default connect(mapStateToProps)(Banner);
