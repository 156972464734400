import React, { useState, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import ArticleContainer from '../articles/container';
import Pad from './Pad';
import Screen from './Screen';
import Detail from './Detail';
import { Column } from '../../layouts';


const Div = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const DetailContainer = styled.div`
    height: ${(props:{height: number}) => `${props.height}px`};
    overflow-y: auto;
`;

export interface ToolProps {
    id: string;
    tool: any;
}

const Tool: React.SFC<ToolProps> = (props:any) => {
    const { tool } = props;
    const padRef = useRef(null);
    const [height, setDetailsHeight] = useState(0);

    useLayoutEffect(() => {
        // @ts-ignore
        if (padRef.current) setDetailsHeight(padRef.current.offsetHeight);
        // @ts-ignore
        window.addEventListener("resize", () => setDetailsHeight(padRef.current.offsetHeight));
        // @ts-ignore
        window.addEventListener("load", () => setDetailsHeight(padRef.current.offsetHeight));
    }, []);

    const toggleFullscreen = async () => {
        const screen = document.getElementById(`iframe-screen-${tool.id}`)
        if (!screen) return;
        if (screen.requestFullscreen) screen.requestFullscreen()
        //@ts-ignore
        else screen.webkitRequestFullscreen()
    }

    return (
        <ArticleContainer xs='12' id={tool.id} withCarousel title={tool.name}>
            <Div>
                <Column xs='12' sm={tool.details ? '7':'12'}>
                    <Pad
                        ref={padRef}
                        inLab={tool.inLab || false}
                        toggleFullscreen={toggleFullscreen}
                    >
                        <Screen tool={tool} />
                    </Pad>
                </Column>
                {
                    tool.details &&
                    <Column xs='10' sm='4'>
                        <DetailContainer height={height}>
                            <Detail tool={tool} />
                        </DetailContainer>
                    </Column>
                }

            </Div>
        </ArticleContainer>
    );
}

export default Tool;