
export const pages = ['Home', 'Philosophy', 'Application', 'approach', 'Team']

export enum GroupEnum {
    TeamMember = 'Auto Analytics Team',
    IgLeader = 'IG/Auto Leader',
    GammaLeader = 'GAMMA Leader',
}
export interface ITeamMember {
    name:string;
    title:string;
    location:string;
    department:string;
    group: GroupEnum;
    hrId:string;
}
export const team:Array<ITeamMember> = [
    { name:'Carsten Schätzberger', title:'MDP', location:'Stuttgart', department:'Leader CEMA IG', hrId:'16840', group: GroupEnum.IgLeader},
    { name: 'Alex Wachtmeister', title: 'MDP', location: 'Munich', department: 'Leader G&A Auto', hrId: '19450', group: GroupEnum.IgLeader },
    { name: 'Christoph Gauger', title: 'MDP', location: 'Stuttgart', department: 'Leader C/D/A', hrId: '62453', group: GroupEnum.IgLeader },
    { name: 'Davide Di Domenico', title: 'MDP', location: 'Milan', department: 'Leader CEMA Auto', hrId: '224732', group: GroupEnum.IgLeader },

    { name: 'Jörg Erlebach', title: 'MDSP', location: 'Frankfurt', department: 'Leader CEMA GAMMA', hrId: '245552', group: GroupEnum.GammaLeader },
    { name: 'Jan Friese', title: 'MDP', location: 'Frankfurt', department: 'Leader CEMA GAMMA IG', hrId: '15387', group: GroupEnum.GammaLeader },

    { name: 'Alfred Wiederer', title: 'MDP', location: 'Munich', department: 'Auto GAMMA Node CEMA', hrId: '18767', group: GroupEnum.TeamMember },
    { name: 'Andrej Levin', title: 'Managing Director and Partner', location: 'Hamburg', department: 'Auto GAMMA Lead CEMA', hrId: '205472', group: GroupEnum.TeamMember },
    { name: 'Nico Geisel', title: 'Associate Director', location: 'Munich', department: 'Auto GAMMA Analytics Lead', hrId: '85752', group: GroupEnum.TeamMember },
    { name: 'Michael Schmälzle', title: 'Principal', location: 'Stuttgart', department: '', hrId: '183352', group: GroupEnum.TeamMember },
    { name: 'Tobias Schmidt', title: 'Principal', location: 'Hamburg', department: '', hrId: '197852', group: GroupEnum.TeamMember },
    { name: 'Quan Nhon Vuong', title: 'GAMMA Lead Analyst', location: 'Düsseldorf', department: '', hrId: '271123', group: GroupEnum.TeamMember },
    { name: 'Shan Hua', title: 'GAMMA Analyst', location: 'Munich', department: '', hrId: '363602', group: GroupEnum.TeamMember },
]