import { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface ScrollHandlerProps extends RouteComponentProps {
    location: {
        pathname: string,
        search: string,
        hash: string,
        state: any,
        key: string
    }
}

const ScrollHandler: React.SFC<ScrollHandlerProps> = ({ location }) => {
    useEffect(() => {
        const element = document.getElementById(location.hash.replace("#", "").replace(" ", "-"));
        const menu = document.getElementById('menu');

        setTimeout(() => {
            window.scrollTo({
                behavior: element ? "smooth" : "auto",
                top: element ? element.offsetTop - (menu ? menu.offsetHeight:0) : 0
            });
        }, 100);
    }, [location]);

    return null;
};

export default withRouter(ScrollHandler);