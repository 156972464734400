import React, {useState} from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { XYPlot, XAxis, YAxis, VerticalGridLines, HorizontalGridLines, 
    MarkSeries, LineSeries, PolygonSeries, LabelSeries, Hint, ChartLabel, MarkSeriesPoint } from 'react-vis';
import Legend from './Legend';

import { connect } from 'react-redux'

import colors from './colors';

const MatrixPlot = styled(XYPlot)`
    position: relative;
`;

const Tooltip = styled.div`
    font-size: 0.8em;
    padding: 0.3em 0.7em;
    background-color: rgba(0,0,0,0.7);
    border-radius: 0.5em;
    color: white;
    line-height: 1.1em;
`;

const LegendContainer = styled.div`
    padding-left: 10%;
`;

const gridlineStyle = {
    strokeDasharray: "3,3",
    stroke: colors.grid,
}
const axisStyle = {
    line: {stroke: colors.axis},
    text: {fontSize: '0.7em'},
}
const lineStyle = {
    stroke: colors.axis,
    strokeWidth: '0.1em',
}
const yLabelStyle = {
    style: {
        transform: 'rotate(-90deg)',
        whiteSpace: 'normal',
    }
}

const polygons = [
    { data: [{ x: 0, y: 40 }, { x: 60, y: 40 }, { x: 60, y: -4 }, { x: 0, y: -4 }], color: colors.bg1, style: { stroke: colors.axis } },
    { data: [{x: 0, y: 40}, {x: 60, y: 40}, {x: 60, y: 0}], color: colors.bg2 },
    { data: [{ x: 15, y: 40 }, { x: 60, y: 40 }, { x: 60, y: 10 }], color: colors.bg3 },
    { data: [{ x: 29, y: 40 }, { x: 60, y: 40 }, { x: 60, y: 21 }], color: colors.bg4 },
]

const legendItems = [
    { title: 'Applications presented on microsite', color: colors.markOnSite },
    { title: 'Applications in preparation', color: colors.markInPrep,},
]

export interface UseCaseMatrixProps extends RouteComponentProps {
    chartWidth: number;
    chartHeight: number;
    prio: string;
    setActiveTool?:any;
    tools:any;
    potentialTools:any;
}
 
const UseCaseMatrix: React.SFC<UseCaseMatrixProps> = (props:any) => {

    const tools = Object.values({...props.tools,...props.potentialTools})
    const { prio } = props
    const [hint, setHint] = useState<any>(undefined)
    const [animation, setAnimation] = useState<boolean>(true)
    const marks = tools.filter((tool:any)=>tool.feasibility && tool.impact && true)
        .map((tool:any) => ({
            x: tool.feasibility && tool.feasibility[prio] || 0,
            y: tool.impact && tool.impact[prio] || 0,
            size: 7,
            url: tool.url,
            name: tool.name,
            category: tool.category,
            order: tool.order,
        }))
    
    const labelMargin = 110;
    const canvasHeight = props.chartHeight - labelMargin - 10;

    const handlePointClick = (datapoint:MarkSeriesPoint) => {
        if (typeof datapoint.order === 'number') {
            props.history.push('/#application')
            console.log('handlePointClick')

            // dispatch action to redux
            props.setActiveTool(datapoint.order)
        }
        
    }

    return ( 
        <>
        <MatrixPlot 
            width={props.chartWidth} 
            height={props.chartHeight} 
            margin={{ left: labelMargin, bottom: labelMargin}}
            style={{overflow: 'visible'}}
            // yDomain={[0, 5, 20, 60]} 
            // yRange={[canvasHeight, canvasHeight * 2 / 3, canvasHeight/3, 0]}
            >
            { polygons.map((polygon, index) => 
                <PolygonSeries key={`use-case-polygon-${index}`} {...polygon} />
                ) }
            <VerticalGridLines tickValues={[10,20,30,40,50,60]} style={gridlineStyle} />
            <HorizontalGridLines tickValues={[0, 10, 20, 30, 40]} style={gridlineStyle} />
            <XAxis hideLine
                tickFormat={(v: number) => {
                    switch (v) {
                        case 0: return 'Low';
                        case 30: return 'Medium';
                        case 60: return 'High';
                        default: return '';
                    }
                }}
                tickValues={[0, 30, 60]} 
                style={axisStyle} />
            <YAxis hideLine 
                tickFormat={(v:number)=>{
                    switch (v) {
                        case 0: return 'Low';
                        case 20: return 'Medium';
                        case 40: return 'High';
                        default: return '';
                    }
                }} 
                tickValues={[0, 20, 40]}
                style={axisStyle}
            />
            <LineSeries data={[{ x: 0, y: 0 }, { x: 60, y: 0 }]} style={lineStyle} />
            <LabelSeries 
                data={[ {x:58, y:-3, label:'Enablers'} ]}
            />
            <MarkSeries
                animation={animation}
                color={colors.markInPrep}
                strokeWidth={2}
                opacity={1}
                data={marks.filter(mark => !mark.url)}
                onValueMouseOver={(datapoint:any) => {
                    setHint(datapoint);
                    setAnimation(false);
                    }
                }
                onValueMouseOut={(datapoint:any) => {
                    setHint(undefined);
                    setAnimation(true);
                }
                }
            />
            <MarkSeries
                animation={animation}
                color={colors.markOnSite}
                strokeWidth={2}
                opacity={1}
                data={marks.filter(mark => mark.url && true)}
                onValueMouseOver={(datapoint:any) => {
                    setHint(datapoint);
                    setAnimation(false);
                }
                }
                onValueMouseOut={(datapoint:any) => {
                    setHint(undefined);
                    setAnimation(true);
                }
                }
                onValueClick={(datapoint:any, e:any) => handlePointClick(datapoint)}
            />
            { hint && 
                <Hint value={hint} style={{pointerEvents: 'none'}}>
                <Tooltip>
                    <p>{hint.name}</p>
                    <p>Category: {hint.category}</p>
                    <p>{hint.fill}</p>
                </Tooltip>
                </Hint>
            }
            <ChartLabel style={yLabelStyle} text={`Financial Impact:`} includeMargin={false} xPercent={-0.17} yPercent={0.8} />
            <ChartLabel style={yLabelStyle} text={`Contribution margin per unit`} includeMargin={false} xPercent={-0.14} yPercent={0.8} />
            <ChartLabel text={`Feasibility: Ease of implementation`} includeMargin={false} xPercent={0.25} yPercent={1.2} />
        </MatrixPlot>
        <LegendContainer>
            {
                legendItems.map((item, index) => 
                    <Legend key={`use-case-legend-${index}`} size={20} width={props.chartWidth} title={item.title} color={item.color} />
                    )
            }
        </LegendContainer>
        
        </>
     );
}

const mapDispatchToProps= (dispatch:any) => {
    return{
        setActiveTool: (orderNumber:number) => dispatch({ type: 'SET_ACTIVE_TOOL', order: orderNumber })
    }
}

const mapStateToProps= (state:any) => {
    return{
        potentialTools: state.variations.potentialTools,
        tools: state.variations.tools
    }
}
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UseCaseMatrix));