import React from 'react';
import styled from 'styled-components';
import { Container, H1, H2 } from '../../layouts';

const FlexContent = styled.div`
    margin: auto 0px;
    max-height: 95%;
`;

export interface ArticleContainerProps {
    id: string;
    h1?: boolean;
    xs?: string;
    doubleCol?: boolean;
    colWidth?: Array<number>;
    title?: string;
    lightTitle?: boolean;
    style?: {};
    withCarousel?: boolean;
}
 
const ArticleContainer: React.SFC<ArticleContainerProps> = (props) => {
    // const colWidth = props.colWidth || [5, 5];
    return (
        <Container xs={props.xs || '10'} {...props}> 
            <FlexContent>
            {props.title && 
                (props.h1 ? 
                <H1 xs='12' lightTitle={props.lightTitle} dangerouslySetInnerHTML={{ __html: props.title }} /> : 
                <H2 xs='12' lightTitle={props.lightTitle} dangerouslySetInnerHTML={{__html: props.title}} />)
            } 
            {props.children}
            </FlexContent>
        </Container>
    );
}
 
export default ArticleContainer;