import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),${(props:{ bgImage:string }) => `url(${props.bgImage})`};
    vertical-align: middle;
`;

const Text = styled.div`
    color: ${props => props.theme.colors.light};
    font-size: 3.6em;
    line-height: 1.1em;
    text-align: center;
    margin: auto;
`;

interface ErrorPageProps {
    bgImage: string;
    children?: React.ReactNode;
}

const ErrorPage: React.SFC<ErrorPageProps> = (props) => {
    return (
        <Div bgImage={props.bgImage}>
            <Text>{props.children}</Text>
        </Div>
    );
}

export default ErrorPage;