export type TAppState = {
    activeToolOrder: number; 
}

const initialAppState:TAppState = {
    activeToolOrder: -1,
}

export type TAction = 
    {type: 'SET_ACTIVE_TOOL', order: number} |
    {type: 'RESET_ACTIVE_TOOL'}

const reducer = (state: TAppState= initialAppState, action: TAction) => {
    switch (action.type) {
        case 'SET_ACTIVE_TOOL': return { ...state, activeToolOrder: action.order };
        case 'RESET_ACTIVE_TOOL': return { ...state, activeToolOrder: -1 };
        default: return state;
    }
}

export default reducer;