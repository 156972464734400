import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid';

import styled from 'styled-components';
import { H2 } from '../../layouts'

const Padding = styled.div`
    padding-bottom: 1em;
`;

const CenterDiv = styled.div`
    text-align:center;
`

const AdjustHieghtImg = styled.img`
    width: auto;
    height:auto;

    max-width: 100%;
    max-height: 400px;

    @media only screen and (min-height: 750px) {
        max-height: 440px;
    }

    @media only screen and (min-height: 820px) {
        max-height: 510px;
    }

    @media only screen and (min-height: 860px) {
        max-height: 550px;
    }

    @media only screen and (min-height: 920px) {
        max-height: 600px;
    }

    @media only screen and (min-height: 1010px) {
        max-height: 700px;
    }

    @media only screen and (min-height: 1100px) {
        max-height: 750px;
    }

    @media only screen and (min-height: 1200px) {
        max-height: 850px;
    }
`
export interface PhilosophyProps {
    id: string;
    gif: string;
    images: any;
    title: string;
    reloading?: boolean;
    withCarousel?: boolean;
}

const Philosophy: React.SFC<PhilosophyProps> = (props: any) => {
    return (
        <Padding>
            <Grid
                container
                direction='row'
                justify='center'
                alignItems='flex-start'
                alignContent='stretch'
            >
                <Grid item sm={12} md={11} lg={11} xl={11}>
                    <Grid
                        container
                        direction='column'
                        justify='flex-start'
                        alignItems='center'
                    >
                        <Grid item>
                            <H2>{props.title}</H2>
                            <CenterDiv>
                                <AdjustHieghtImg src={props.images[props.gif]} />
                            </CenterDiv>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Padding>

    );
}

const mapStateToProps = (state: any) => {
    return {
        images: state.images
    }
}

export default connect(mapStateToProps)(Philosophy);
