import React, {useRef, useState, useEffect} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { kebabCase, camelCase } from 'lodash'
import Button from '@material-ui/core/Button'
import ArticleContainer from '../articles/container'
import UseCaseMatrix from '../UseCaseMatrix'
import Gif from '../articles/gif'
import { Column } from '../../layouts'


const Div = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const SubTitle = styled.div`
    padding: 1em 0;
    font-size: 1.4em;
    color: ${props => props.theme.colors.brand};
`;

const ButtonRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
    padding-left: 5em;
    justify-content: center;
`;

const FuncButton = styled(Button)`
    flex: 1 1 50%;
    margin: 0.3em;
    font-weight: ${(props:{active:boolean}) => props.active ? 'bolder' : 'normal'};
    color: ${(props: { active: boolean, theme:any }) => props.active ? props.theme.colors.secondary : 'unset'};
`;

export interface UseCaseOverviewProps {
    id: string;
    structure:any;
    priority:any;
    title:any;
    images:any;
}
 
const UseCaseOverview: React.SFC<UseCaseOverviewProps> = (props:any) => {
    const container = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [activePrio, setActivePrio] = useState("Cost reduction");
    useEffect(() => {
        // @ts-ignore
        if (container.current) setContainerWidth(container.current.offsetWidth)
        // @ts-ignore
        window.addEventListener("resize", () => setContainerWidth(container.current.offsetWidth));
    }, [])
    return ( 
        <ArticleContainer {...props} title={ props.title.text }>
            
            <Div ref={container}>
                <Column xs='12' sm='6'>
                    <SubTitle>{ props.structure.title.text }</SubTitle>
                    <Gif src={ props.images[props.structure.image] } />
                </Column>
                <Column xs='12' sm='6'>
                    <SubTitle>{ props.priority.title.text }</SubTitle>
                    <ButtonRow>
                        { ['Revenue growth', 'Cost reduction' ].map(item => 
                            <FuncButton 
                            key={`use-case-button-${kebabCase(item)}`} 
                            variant='contained' 
                            onClick={()=>setActivePrio(item)}
                            active={activePrio===item}>
                            {item}
                            </FuncButton>
                            )}
                    </ButtonRow>
                    <UseCaseMatrix prio={camelCase(activePrio)} chartWidth={containerWidth / 2} chartHeight={containerWidth * 3 / 8} />
                </Column>
            </Div>         
        </ArticleContainer>
        
     );
}
 
const mapStateToProps= (state:any) => {
    return{
        structure: state.variations.UseCaseOverview.structure,
        priority: state.variations.UseCaseOverview.priority,
        title: state.variations.UseCaseOverview.title,
        images: state.images
    }
}
 
export default connect(mapStateToProps)(UseCaseOverview);
