import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Security, SecureRoute, ImplicitCallback } from '@okta/okta-react';
import { ThemeProvider } from 'styled-components';
import { StylesProvider } from '@material-ui/styles';
import { Helmet } from "react-helmet";


import MainPage from './main';
import Page401 from './error-pages/401';
import Page404 from './error-pages/404';
import Login from './components/OktaLogin/Login';
import PageLoggedIn from './components/OktaLogin/PageLoggedIn'
import SiteOverviewPage from './components/overview-sites/SiteOverviewPage'
import { theme } from './theme';

import { Provider } from 'react-redux';
import { store } from './redux/index'



function onAuthRequired({history}:any) {
    history.push('/login');
  }

const App: React.SFC = () => {
    const variations:any = store.getState().variations
    const document:any = variations.document
    const ISSUER:any= process.env.REACT_APP_ISSUER
    const OKTA_DOMAIN:any= process.env.REACT_APP_OKTA_DOMAIN
    const OKTA_CLIENT_ID:any= process.env.REACT_APP_OKTA_CLIENT_ID
    
    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="shortcut icon" href="/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <title>{document.title.text}</title>
                <link rel="canonical" href="http://mysite.com/example" />
                <link rel="manifest" href="/manifest.json" />
                <script src="https://tableau.bcg.com/javascripts/api/tableau-2.min.js"></script>
            </Helmet>
            <ThemeProvider theme={theme}>
                <StylesProvider injectFirst>
                    <Provider store={store}>
                        <BrowserRouter basename={process.env.PUBLIC_URL}>
                            <Security issuer={ISSUER}
                                clientId={OKTA_CLIENT_ID}
                                redirectUri={window.location.origin + '/implicit/callback'}
                                onAuthRequired={onAuthRequired}
                                pkce={true} >
                                <Switch>
                                    <SecureRoute exact path='/' component={MainPage} />
                                    <Route path='/login' render={() => <Login baseUrl={OKTA_DOMAIN} />} />
                                    <Route path='/401' component={Page401} />
                                    <SecureRoute exact path='/all' component={SiteOverviewPage} />
                                    <Route path='/implicit/callback' component={ImplicitCallback} />
                                    <Route component={Page404} />
                                </Switch>
                            </Security>
                        </BrowserRouter>
                    </Provider>
                </StylesProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;