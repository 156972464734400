import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled.div`
    padding-top: 6em;
    padding-bottom: 10em;
    font-size: 0.6em;
    line-height: 1em;
    color: #9e9e9e;
    text-align: center;
`;
 
const Footer: React.SFC = () => {
    return ( 
        <FooterContainer>
            {/* <hr style={{width: '40%'}} /> */}
            <p>&copy; 2019 Boston Consulting Group</p>
            <p>For technical issues please contact <a href="mailto:gamma@bcg.com">GAMMA</a></p>
        </FooterContainer>
     );
}
 
export default Footer;