import React from 'react';
import ErrorPage from '.';
import Image from './assets/404.jpg';


 
const Page404: React.SFC = () => {
    return ( 
        <ErrorPage bgImage={Image}>
            This tool is under active development.<br />
            <small>Please contact our team to get information about any updates. We really appreciate your support!</small><br />
        </ErrorPage>
     );
}
 
export default Page404;