import React from 'react';
import ErrorPage from '.';
import Image from './assets/401.jpg';



const Page401: React.SFC = () => {
    return (
        <ErrorPage bgImage={Image}>
            Sorry, you are not authorized to this tool.<br />
            <small>Please ask our team for the access.</small><br />
        </ErrorPage>
    );
}

export default Page401;